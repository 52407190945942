import React from 'react'

export const About = () => {
  return (
    <div className='page'>
      <h1 className='heading'>About</h1>

      <h2>Experience</h2>
      <ul>

      </ul>

      <h2>knowledge</h2>
      <ul>
        
      </ul>

      <h2>studies</h2>
      <ul>
        
      </ul>
    </div>
  )
}
